<template>
    <div>
        备份恢复数据库
    </div>
</template>

<script>
export default {
    name: "SysData"
}
</script>

<style scoped>

</style>