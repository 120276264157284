<template>
    <div style="width: 500px">
        <el-input
            placeholder="请输入部门名称进行搜索..."
            prefix-icon="el-icon-search"
            v-model="filterText">
        </el-input>

        <el-tree
            :data="deps"
            :props="defaultProps"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            ref="tree">
            <!--slot-scope就是我们可以自定义节点里的内容
            node就是我们当前节点的node对象，data就是我们后端返回的一个对应的数据-->
            <span class="custom-tree-node" slot-scope="{ node, data }"
                  style="display: flex;justify-content: space-between;width: 100%;">
                <span>{{ data.name }}</span>
                <span>
                      <el-button
                          type="primary"
                          size="mini"
                          class="depBtn"
                          @click="() => showAddDep(data)">
                        添加部门
                      </el-button>
                      <el-button
                          type="danger"
                          size="mini"
                          class="depBtn"
                          @click="() => deleteDep(data)">
                        删除部门
                      </el-button>
                </span>
            </span>
        </el-tree>

        <el-dialog
            title="添加部门"
            :visible.sync="dialogVisible"
            width="30%">
            <div>
                <table>
                    <tr>
                        <td>
                            <el-tag>上级部门</el-tag>
                        </td>
                        <td>{{ pname }}</td>
                    </tr>
                    <tr>
                        <td>
                            <el-tag>部门名称</el-tag>
                        </td>
                        <td>
                            <el-input v-model="dep.name" placeholder="请输入部门名称"></el-input>
                        </td>
                    </tr>
                </table>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddDep">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "DepMana",
    data() {
        return {
            filterText: '',
            deps: [],
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            dialogVisible: false,
            dep: {
                name: '',
                parentId: -1
            },
            pname: ''
        }
    },
    watch: { /*这是观察者事件，就是说它会去观察filterText传的一个值
    它发现有值的时候呢它就会去找到tree控件的filterNode方法;换句话来说就是监听值的变化*/
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    mounted() {
        this.initDeps();
    },
    methods: {
        initDep() {
            this.dep = {
                name: '',
                parentId: -1
            }
            this.pname = '';
        },
        addDep2Deps(deps, dep) {
            for (let i = 0; i < deps.length; i++) {
                let d = deps[i];
                if (d.id == dep.parentId) {
                    d.children = d.children.concat(dep);
                    if (d.children.length > 0) {
                        d.isParent = true;
                    }
                    return;
                } else {
                    this.addDep2Deps(d.children, dep);
                }
            }

        },
        doAddDep() {
            this.postRequest('/system/basic/department/', this.dep).then(resp => {
                if (resp) {
                    this.addDep2Deps(this.deps, resp.obj);
                    this.dialogVisible = false;
                    this.initDep();
                }
            })

        },
        showAddDep(data) {
            this.dep.parentId = data.id;
            this.pname = data.name;
            this.dialogVisible = true;

        },
        removeDepFromDeps(p, deps, id) {
            for (let i = 0; i < deps.length; i++) {
                let d = deps[i];
                if (d.id == id) {
                    deps.splice(i, 1);
                    if (deps.length == 0) {
                        p.isParent = false;
                    }
                    return;

                } else {
                    this.removeDepFromDeps(d, d.children, id);
                }
            }
        },
        deleteDep(data) {
            if (data.isParent) {
                this.$message.error('父部门删除失败！');

            } else {
                this.$confirm('此操作将永久删除该[' + data.name + ']部门, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.deleteRequest('/system/basic/department/' + data.id).then(resp => {
                        if (resp) {
                            this.removeDepFromDeps(null, this.deps, data.id);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
        initDeps() {
            this.getRequest('/system/basic/department/').then(resp => {
                if (resp) {
                    this.deps = resp;
                }
            })

        },
        filterNode(value, data) { /*这是拦截节点的事件*/
            if (!value) return true; /*true是展示，false是不展示
            如果把搜索框清空了，那整棵树形控件就会全部展示开来*/
            return data.name.indexOf(value) !== -1;
        }
    }
}
</script>

<style>
.depBtn {
    padding: 2px;
}
</style>